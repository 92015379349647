import Vue from "vue";
import Vuex from "vuex";
import regis from "./regis/index";
import login from "./login/index";
import home from "./home/index";
import transaksi from "./transaksi/index";
import edit from "./edit/index";
import firebase from "../firebase";

Vue.use(Vuex);
Vue.use(firebase);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { regis, login, home, transaksi, edit },
});
