<template>
  <v-app style="background: #068bd0; height: 200px">
    <v-container>
      <v-row no-gutters>
        <v-col cols="12">
          <h1 class="white--text">Catat Transaksi</h1>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="mt-8">
          <v-card
            v-if="$vuetify.breakpoint.xs"
            class="mx-auto"
            elevation="24"
            shaped
            max-width="300"
          >
            <v-card-text>
              <div class="utama">
                Saldo
                <img
                  style="margin-top: -5%"
                  class="float-right"
                  height="50"
                  width="50"
                  src="../assets/logo.svg"
                  alt="Logo"
                />
              </div>
              <div class="utama">
                Rp{{
                  Intl.NumberFormat().format(this.pemasukan - this.pengeluaran)
                }}
              </div>
              <div class="mt-2 text-start pemasukan">
                Pemasukan
                <span class="float-right pengeluaran"> Pengeluaran </span>
              </div>
              <div class="text-start pemasukan">
                Rp{{ Intl.NumberFormat().format(this.pemasukan) }}
                <span class="float-right pengeluaran"
                  >Rp{{ Intl.NumberFormat().format(this.pengeluaran) }}
                </span>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            v-if="
              $vuetify.breakpoint.lg ||
              $vuetify.breakpoint.md ||
              $vuetify.breakpoint.xl ||
              $vuetify.breakpoint.sm
            "
            class="mx-auto"
            elevation="24"
            shaped
            max-width="400"
          >
            <v-card-text>
              <div class="utama">
                Saldo
                <img
                  style="margin-top: -1%"
                  class="float-right"
                  height="50"
                  width="50"
                  src="../assets/logo.svg"
                  alt="Logo"
                />
              </div>
              <div class="utama">
                Rp{{
                  Intl.NumberFormat().format(this.pemasukan - this.pengeluaran)
                }}
              </div>
              <div class="mt-2 text-start pemasukan">
                Pemasukan
                <span class="float-right pengeluaran"> Pengeluaran </span>
              </div>
              <div class="text-start pemasukan">
                Rp{{ Intl.NumberFormat().format(this.pemasukan) }}
                <span class="float-right pengeluaran"
                  >Rp{{ Intl.NumberFormat().format(this.pengeluaran) }}
                </span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="mt-2">
          <v-form @submit="save">
            <v-text-field
              v-model="input.name"
              dense
              label="Nama Transaksi"
              outlined
              color="primary"
            ></v-text-field>
            <v-text-field
              v-model="input.nomor"
              dense
              label="Nominal Transaksi"
              outlined
              color="primary"
            ></v-text-field>
            <v-radio-group v-model="input.type" row>
              <v-radio
                class="green--text"
                style="color: #08ab3f"
                color="success"
                label="Pemasukan"
                value="pemasukan"
              ></v-radio>
              <v-radio
                class="red--text"
                style="color: #f9010b"
                color="warning"
                label="Pengeluaran"
                value="pengeluaran"
              ></v-radio>
            </v-radio-group>
            <div class="mt-15 text-center">
              <v-btn
                color="primary"
                type="submit"
                elevation="10"
                block
                @click.prevent="save"
                depressed
                >Simpan</v-btn
              >
            </div>
            <div class="mt-3 text-center">
              <v-btn
                color="white"
                style="color: #f9010b"
                elevation="10"
                block
                @click.prevent="cancel"
                depressed
                >Kembali</v-btn
              >
            </div>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="justify-center"
          ><v-icon size="80" :color="color">
            {{ icon }}
          </v-icon></v-card-title
        >
        <v-card-text class="title text-center">
          {{ message }}
        </v-card-text>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn :color="color" outlined @click="dialog = false" type="submit"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  data: () => ({
    icon: "",
    color: "",
    message: "",
    dialog: false,
    input: {
      type: "",
      name: "",
      nomor: "",
    },
    items: [
      {
        type: "pengeluaran",
        namaTransaksi: "Beli Laptop",
        tanggalTransaksi: "Jumat, 5 Maret 2021",
        nominal: "Rp. 250000",
      },
      {
        type: "pemasukan",
        namaTransaksi: "Beli Laptop",
        tanggalTransaksi: "Jumat, 5 Maret 2021",
        nominal: "Rp. 250000",
      },
      {
        type: "pengeluaran",
        namaTransaksi: "Beli Laptop",
        tanggalTransaksi: "Jumat, 5 Maret 2021",
        nominal: "Rp. 250000",
      },
      {
        type: "pemasukan",
        namaTransaksi: "Beli Laptop",
        tanggalTransaksi: "Jumat, 5 Maret 2021",
        nominal: "Rp. 250000",
      },
      {
        type: "pengeluaran",
        namaTransaksi: "Beli Laptop",
        tanggalTransaksi: "Jumat, 5 Maret 2021",
        nominal: "Rp. 250000",
      },
      {
        type: "pemasukan",
        namaTransaksi: "Beli Laptop",
        tanggalTransaksi: "Jumat, 5 Maret 2021",
        nominal: "Rp. 250000",
      },
    ],
    pemasukan: 0,
    pengeluaran: 0,
    dataDashboard: [],
    dataForEdit: [],
  }),
  mounted() {
    this.getDatas();
    this.getDatasEdit();
  },
  computed: {
    ...mapState("edit", ["data"]),
    ...mapState("edit", ["dataEdit"]),
    ...mapState("edit", ["dataPush"]),
  },
  methods: {
    ...mapMutations("edit", ["SET_DATA"]),
    ...mapActions("edit", ["getData"]),
    ...mapMutations("edit", ["SET_DATA_EDIT"]),
    ...mapActions("edit", ["getDataEdit"]),
    ...mapMutations("edit", ["SET_DATA_PUSH"]),
    ...mapActions("edit", ["saveAction"]),
    async getDatas() {
      await this.getData().then(() => {
        console.log("gettt");
        this.setData();
      });
    },

    async getDatasEdit() {
      await this.getDataEdit().then(() => {
        console.log("gettt");
        this.setDataEdit();
      });
    },

    setData() {
      console.log("gettt222");
      this.dataDashboard = this.data;
      this.pengeluaran = sessionStorage.getItem("pengeluaran");
      this.pemasukan = sessionStorage.getItem("pemasukan");
      console.log(this.dataDashboard);
    },

    setDataEdit() {
      console.log("gettt222");
      this.dataForEdit = this.dataEdit;
      this.input.name = this.dataForEdit.title;
      this.input.nomor = this.dataForEdit.nominal;
      this.input.type = this.dataForEdit.status;
      console.log(this.dataForEdit);
    },

    save() {
      if (this.input.name != "" && this.input.nomor != "") {
        this.saveAction({
          title: this.input.name,
          nominal: this.input.nomor,
          date: this.dataForEdit.date,
          id: this.dataForEdit.id,
          status: this.input.type,
        }).then(() => {
          this.dialog = true;
          this.color = "success";
          this.icon = "mdi-check-circle-outline";
          this.message = "Berhasil Diubah";
          setTimeout(() => {
            this.$router.push({ name: "Home" });
          }, 3000);
        });
      } else {
        this.color = "error";
        this.icon = "mdi-alert-circle-outline";
        this.message = "Field Tidak Boleh Kosong";
        this.dialog = true;
      }
    },
    cancel() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style scoped>
.green--text /deep/ label {
  color: #08ab3f;
}

.red--text /deep/ label {
  color: #f9010b;
}
</style>