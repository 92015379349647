import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#068BD0",
        secondary: "#F8F7FC",
        accent: "#25b4f4",
        error: "#EA2620",
        info: "#2097F3",
        success: "#08AB3F",
        warning: "#F9010B",
        avg: "#899CAA",
        max: "#527A98",
        darkblue: "#343954",
        white: "#FFFFFF",
      },
      dark: {
        primary: "#1D223C",
        secondary: "#15192B",
        accent: colors.teal.lighten3,
        error: "#EA2620",
        info: "#2097F3",
        success: "#08AB3F",
        warning: "#F9010B",
        avg: "#899CAA",
        max: "#527A98",
        darkblue: "#343954",
        white: "#FFFFFF",
      },
    },
  },
});
