import firebase from "firebase/app";

const defaultState = {
  regisState: [],
  regisStatus: "false",
  authenticated: false,
};

const mutations = {
  setRegisData(state, em) {
    state.regisState = em;
  },
  setRegisStatus(state, em) {
    state.regisStatus = em;
  },
  setAuth(state, em) {
    state.authenticated = em;
  },
};

const actions = {
  async regisAction({ commit }, data) {
    var database = firebase.database().ref("User");
    await database.child(data.username).once("value", (snapshot) => {
      console.log(snapshot.val());
      if (snapshot.val() === null) {
        commit("setRegisStatus", "true");
        commit("setRegisData", snapshot.val());
        sessionStorage.setItem("loginSession", data);
        sessionStorage.setItem("usernameSession", data.username);
        database.child(data.username).set(data);
      } else {
        commit("setRegisStatus", "false");
        sessionStorage.removeItem("loginSession");
        sessionStorage.removeItem("usernameSession");
      }
    });
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
