<template>
  <v-app class="home">
    <v-container fill-height="fill-height">
      <v-layout align-center="align-center" justify-center="justify-center">
        <v-flex class="login-form text-xs-center">
          <v-row align="center" justify="center" no-gutters>
            <v-col align="center" cols="12" sm="6" md="5" lg="4">
              <v-img
                class="mb-15"
                max-width="250"
                lazy-src="../assets/logo.svg"
                src="../assets/logo.svg"
              >
              </v-img>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="12" sm="6" md="5" lg="4">
              <v-form @submit="login" v-model="isValid">
                <v-text-field
                  v-model="input.username"
                  dense
                  label="Username"
                  outlined
                  color="primary"
                  :rules="[(v) => !!v || 'Username is required']"
                  required
                ></v-text-field>
                <v-text-field
                  type="password"
                  v-model="input.password"
                  dense
                  outlined
                  :rules="[(v) => !!v || 'Password is required']"
                  label="Password"
                  color="primary"
                  required
                ></v-text-field>
                <v-btn
                  class="mt-2"
                  color="primary"
                  block
                  elevation="10"
                  type="submit"
                  @click.prevent="login"
                  :disabled="!isValid"
                  depressed
                  >Masuk</v-btn
                >
              </v-form>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" no-gutters>
            <v-col align="center" cols="12" sm="6" md="5" lg="4">
              <span>Belum Punya Akun ? </span>
              <a v-on:click="regis">Daftar</a>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="justify-center"
          ><v-icon size="80" :color="color">
            {{ icon }}
          </v-icon></v-card-title
        >
        <v-card-text class="title text-center">
          {{ message }}
        </v-card-text>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn :color="color" outlined @click="dialog = false" type="submit"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
// import router from "@/router/index";

export default {
  data: () => ({
    icon: "",
    color: "",
    message: "",
    dialog: false,
    isValid: true,
    input: {
      username: "",
      password: "",
    },
    portrait: true,
    landscape: false,
  }),
  mounted() {
    window.addEventListener("orientationchange", this.handleOrientationChange);
  },
  computed: {
    ...mapState("login", ["loginStatus"]),
  },
  methods: {
    ...mapActions("login", ["loginAction"]),
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        // portrait mode
        this.landscape = false;
        this.portrait = true;
      } else if (orientation === "landscape-primary") {
        // landscape mode
        this.landscape = true;
        this.portrait = false;
      }
    },
    login() {
      if (this.input.username != "" && this.input.password != "") {
        // this.dialog = true;
        // this.color = "success";
        // this.icon = "mdi-check-circle-outline";
        // this.message = "Berhasil";
        // setTimeout(() => {
        //   sessionStorage.setItem("loginSession", "data");
        //   this.$router.push({ name: "Home" });
        // }, 5000);
        // router.push({ name: "Home" });
        this.loginAction({
          username: this.input.username,
          password: this.input.password,
        })
          .then(() => {
            console.log(this.loginStatus);
            if (this.loginStatus === "true") {
              this.dialog = true;
              this.color = "success";
              this.icon = "mdi-check-circle-outline";
              this.message = "Berhasil";
              setTimeout(() => {
                this.$router.push({ name: "Home" });
              }, 2000);
            } else {
              this.color = "error";
              this.icon = "mdi-alert-circle-outline";
              this.message = "Username atau Password Salah";
              this.dialog = true;
            }
          })
          .catch((error) => {
            console.log(error); // this is the main part. Use the response property from the error object
            this.color = "error";
            this.icon = "mdi-alert-circle-outline";
            this.message = "Username atau Password Salah";
            this.dialog = true;
          });
      } else {
        this.color = "error";
        this.icon = "mdi-alert-circle-outline";
        this.message = "Username dan password harus diisi";
        this.dialog = true;
      }
    },
    regis() {
      this.$router.push({ name: "Register" });
    },
  },
};
</script>

<style></style>