<template>
  <v-app style="background: #068bd0; height: 200px">
    <v-container>
      <v-row no-gutters>
        <v-col cols="12">
          <div class="ml-3 mt-2 mr-3 float-left">
            <img
              src="../assets/profile.png"
              height="50"
              width="50"
              alt="Profile"
            />
          </div>
          <div
            v-on:click="logout"
            class="ml-3 mt-2 mr-3 float-right text-center"
          >
            <img
              src="../assets/logout.svg"
              height="30"
              width="30"
              alt="Profile"
              v-on:click="logout"
            />
            <p style="color: #fab360" v-on:click="logout">Logout</p>
          </div>

          <v-main class="mt-3 ml-10 white--text">
            <span>{{ this.dataDashboard.nama }}</span>
            <v-spacer></v-spacer>
            <span>{{ this.dataDashboard.profesi }}</span>
          </v-main>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card
            v-if="$vuetify.breakpoint.xs"
            class="mx-auto"
            elevation="24"
            shaped
            max-width="300"
          >
            <v-card-text>
              <div class="utama">
                Saldo
                <img
                  style="margin-top: -5%"
                  class="float-right"
                  height="50"
                  width="50"
                  src="../assets/logo.svg"
                  alt="Logo"
                />
              </div>
              <div class="utama">
                Rp{{
                  Intl.NumberFormat().format(
                    this.datasetIncome - this.datasetOutcome
                  )
                }}
              </div>
              <div class="mt-2 text-start pemasukan">
                Pemasukan
                <span class="float-right pengeluaran"> Pengeluaran </span>
              </div>
              <div class="text-start pemasukan">
                Rp{{ Intl.NumberFormat().format(this.datasetIncome) }}
                <span class="float-right pengeluaran"
                  >Rp{{ Intl.NumberFormat().format(this.datasetOutcome) }}
                </span>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            v-if="
              $vuetify.breakpoint.lg ||
              $vuetify.breakpoint.md ||
              $vuetify.breakpoint.xl ||
              $vuetify.breakpoint.sm
            "
            class="mx-auto"
            elevation="24"
            shaped
            max-width="400"
          >
            <v-card-text>
              <div class="utama">
                Saldo
                <img
                  style="margin-top: -1%"
                  class="float-right"
                  height="50"
                  width="50"
                  src="../assets/logo.svg"
                  alt="Logo"
                />
              </div>
              <div class="utama">
                Rp{{
                  Intl.NumberFormat().format(
                    this.datasetIncome - this.datasetOutcome
                  )
                }}
              </div>
              <div class="mt-2 text-start pemasukan">
                Pemasukan
                <span class="float-right pengeluaran"> Pengeluaran </span>
              </div>
              <div class="text-start pemasukan">
                Rp{{ Intl.NumberFormat().format(this.datasetIncome) }}
                <span class="float-right pengeluaran"
                  >Rp{{ Intl.NumberFormat().format(this.datasetOutcome) }}
                </span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          class="mt-2"
          v-for="(item, i) in dataTransaction"
          :key="i"
        >
          <div @click="onClick(item)">
            <div class="ml-3 mt-3 mr-3 float-left">
              <img :src="require(`@/assets/` + item.status + `.svg`)" />
            </div>
            <v-content>
              <div :class="['text-start', item.status]">
                {{ item.title }}
                <span :class="['float-right', 'mt-3', item.status]"
                  >Rp{{ Intl.NumberFormat().format(item.nominal) }}
                </span>
              </div>
              <v-spacer></v-spacer>
              <span :class="item.status">{{ item.date }}</span>
            </v-content>
          </div>
        </v-col>
      </v-row>

      <v-fab-transition>
        <v-btn @click="toAdd" color="primary" fixed bottom right fab>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-container>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>{{ message }}</v-card-title>
        <v-row>
          <v-col cols="12">
            <div class="ml-5 mr-3 float-left">
              <img :src="imgStatus" />
            </div>
            <span :class="[typeStatus]">{{ typeStatus }}</span>
          </v-col>
          <v-col cols="12">
            <div class="ml-5 mr-3 float-left">
              <img width="30" height="30" :src="imgStatusWal" />
            </div>
            <span :class="[typeStatus]"
              >Rp{{ Intl.NumberFormat().format(nominals) }}</span
            >
          </v-col>
        </v-row>
        <v-card-actions class="pa-6">
          <v-btn color="#f9010b" outlined @click="toDelete" type="submit"
            >Hapus</v-btn
          >
          <v-spacer></v-spacer><v-spacer></v-spacer>
          <v-btn color="#08ab3f" @click="toEdit" type="submit">Edit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog2" max-width="500px">
      <v-card>
        <v-card-title class="justify-center"
          ><v-icon size="80" :color="color">
            {{ icon }}
          </v-icon></v-card-title
        >
        <v-card-text class="title text-center">
          {{ message }}
        </v-card-text>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn :color="color" outlined @click="dialog2 = false" type="submit"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog3" max-width="500px">
      <v-card>
        <v-card-title style="color: #f9010b" class="justify-center"
          >Yakin Mau Keluar ?</v-card-title
        >
        <v-card-title color="f9010b" class="justify-center">
          <v-icon class="justify-center" size="80" color="#f9010b">
            {{ icon }}
          </v-icon></v-card-title
        >
        <v-card-actions class="pa-6">
          <v-btn color="#f9010b" outlined @click="dialog3 = false" type="submit"
            >Batal</v-btn
          >
          <v-spacer></v-spacer><v-spacer></v-spacer>
          <v-btn
            class="white--text"
            color="#f9010b"
            @click="logoutModal"
            type="submit"
            >Keluar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  data: () => ({
    items: [
      {
        type: "pengeluaran",
        namaTransaksi: "Beli Laptop",
        tanggalTransaksi: "Jumat, 5 Maret 2021",
        nominal: "Rp. 250000",
      },
      {
        type: "pemasukan",
        namaTransaksi: "Beli Laptop",
        tanggalTransaksi: "Jumat, 5 Maret 2021",
        nominal: "Rp. 250000",
      },
      {
        type: "pengeluaran",
        namaTransaksi: "Beli Laptop",
        tanggalTransaksi: "Jumat, 5 Maret 2021",
        nominal: "Rp. 250000",
      },
      {
        type: "pemasukan",
        namaTransaksi: "Beli Laptop",
        tanggalTransaksi: "Jumat, 5 Maret 2021",
        nominal: "Rp. 250000",
      },
      {
        type: "pengeluaran",
        namaTransaksi: "Beli Laptop",
        tanggalTransaksi: "Jumat, 5 Maret 2021",
        nominal: "Rp. 250000",
      },
      {
        type: "pemasukan",
        namaTransaksi: "Beli Laptop",
        tanggalTransaksi: "Jumat, 5 Maret 2021",
        nominal: "Rp. 250000",
      },
    ],
    dialog: false,
    dialog2: false,
    dialog3: false,
    icon: "",
    color: "",
    message: "",
    nominals: "",
    typeStatus: "",
    imgStatus: "",
    imgStatusWal: "",
    nomiminal: "",
    indexEdit: "",
    dataTransaction: [],
    dataDashboard: [],
    datasetIncome: [],
    datasetOutcome: [],
  }),
  watch: {
    data(nv, old) {
      this.getDatas();
    },
    dataTf() {
      this.getDatasTf();
      this.sumIncome();
      this.sumOutCome();
    },
  },
  mounted() {
    this.getDatas();
    this.getDatasTf();
  },
  computed: {
    ...mapState("home", ["data"]),
    ...mapState("home", ["dataTf"]),
    ...mapState("home", ["dataDelete"]),
  },

  methods: {
    ...mapMutations("home", ["SET_DATA"]),
    ...mapMutations("home", ["SET_DATA_TF"]),
    ...mapMutations("home", ["SET_DATA_DELETE"]),
    ...mapActions("home", ["getData"]),
    ...mapActions("home", ["getDataTf"]),
    ...mapActions("home", ["deleteAction"]),
    ...mapActions("login", ["logoutAction"]),

    async getDatas() {
      await this.getData()
        .then(() => {
          console.log("gettt");
          this.setData();
        })
        .catch((error) => {
          console.log(error);
          this.setData();
        });
    },

    async getDatasTf() {
      await this.getDataTf()
        .then(() => {
          console.log("getf");
          this.setDataTf();
          this.sumIncome();
          this.sumOutCome();
        })
        .catch((error) => {
          console.log(error);
          this.setData();
        });
    },

    setData() {
      this.dataDashboard = this.data;
    },

    setDataTf() {
      this.dataTransaction = this.dataTf;
    },
    onClick(index) {
      console.log("click" + index);
      sessionStorage.setItem("idForEdit", index.id);
      this.dialog = true;
      this.indexEdit = index.id;
      this.nominals = index.nominal;
      this.typeStatus = index.status;
      this.imgStatus = require(`@/assets/` + index.status + `.svg`);
      this.imgStatusWal = require(`@/assets/wallet_` + index.status + `.svg`);
      this.message = index.title;
    },
    logout() {
      this.dialog3 = true;
      this.icon = "mdi-alert-circle-outline";
    },
    logoutModal() {
      this.logoutAction();
      this.$router.push({ name: "Login" });
    },
    toAdd() {
      this.$router.push({ name: "Transaksi" });
    },
    toDelete() {
      this.deleteAction()
        .then(() => {
          console.log(this.dataDelete);
          if (this.dataDelete === "delete") {
            this.dataTransaction = [];
            this.dataDashboard = [];
            this.dialog = false;
            this.dialog2 = true;
            this.color = "success";
            this.icon = "mdi-check-circle-outline";
            this.message = "Berhasil Dihapus";
          } else {
            this.color = "error";
            this.icon = "mdi-alert-circle-outline";
            this.message = "Gagal Dihapus";
            this.dialog = false;
            this.dialog2 = true;
          }
        })
        .catch((error) => {
          console.log(error); // this is the main part. Use the response property from the error object
          this.color = "error";
          this.icon = "mdi-alert-circle-outline";
          this.message = "Gagal Dihapus";
          this.dialog = false;
          this.dialog2 = true;
        });
    },
    toEdit() {
      this.$router.push({
        name: "EditTransaksi",
        params: {
          id: this.indexEdit,
        },
      });
    },
    sumIncome() {
      let items = [];
      this.dataTransaction.forEach((value) => {
        if (value.status === "pemasukan") {
          var childData = value.nominal;
          items.push(childData);
        }
      });
      if (items.length === 0) {
        let nulls = [0];
        const reducer = (total, currentValue) => total + currentValue;
        this.datasetIncome = nulls.reduce(reducer);
        sessionStorage.setItem("pemasukan", this.datasetIncome);
      } else {
        const reducer = (total, currentValue) => total + currentValue;
        this.datasetIncome = items.reduce(reducer);
        sessionStorage.setItem("pemasukan", this.datasetIncome);
      }
    },
    sumOutCome() {
      let items = [];
      this.dataTransaction.forEach((value) => {
        if (value.status === "pengeluaran") {
          var childData = value.nominal;
          items.push(childData);
        }
      });
      if (items.length === 0) {
        let nulls = [0];
        const reducer = (total, currentValue) => total + currentValue;
        this.datasetOutcome = nulls.reduce(reducer);
        sessionStorage.setItem("pengeluaran", this.datasetOutcome);
      } else {
        const reducer = (total, currentValue) => total + currentValue;
        this.datasetOutcome = items.reduce(reducer);
        sessionStorage.setItem("pengeluaran", this.datasetOutcome);
      }
    },
  },
};
</script>

<style>
</style>