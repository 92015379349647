<template>
  <div class="home">
    <AddTransaksi />
  </div>
</template>

<script>
// @ is an alias to /src
import AddTransaksi from "@/components/AddTransaksi.vue";

export default {
  name: "Home",
  components: {
    AddTransaksi,
  },
};
</script>
