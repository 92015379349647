<template>
  <v-app style="background: #068bd0; height: 200px">
    <v-container>
      <v-row no-gutters>
        <v-col cols="12">
          <h1 class="white--text">Catat Transaksi</h1>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="mt-8">
          <v-card
            v-if="$vuetify.breakpoint.xs"
            class="mx-auto"
            elevation="24"
            shaped
            max-width="300"
          >
            <v-card-text>
              <div class="utama">
                Saldo
                <img
                  style="margin-top: -5%"
                  class="float-right"
                  height="50"
                  width="50"
                  src="../assets/logo.svg"
                  alt="Logo"
                />
              </div>
              <div class="utama">
                Rp{{
                  Intl.NumberFormat().format(this.pemasukan - this.pengeluaran)
                }}
              </div>
              <div class="mt-2 text-start pemasukan">
                Pemasukan
                <span class="float-right pengeluaran"> Pengeluaran </span>
              </div>
              <div class="text-start pemasukan">
                Rp{{ Intl.NumberFormat().format(this.pemasukan) }}
                <span class="float-right pengeluaran"
                  >Rp{{ Intl.NumberFormat().format(this.pengeluaran) }}
                </span>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            v-if="
              $vuetify.breakpoint.lg ||
              $vuetify.breakpoint.md ||
              $vuetify.breakpoint.xl ||
              $vuetify.breakpoint.sm
            "
            class="mx-auto"
            elevation="24"
            shaped
            max-width="400"
          >
            <v-card-text>
              <div class="utama">
                Saldo
                <img
                  style="margin-top: -1%"
                  class="float-right"
                  height="50"
                  width="50"
                  src="../assets/logo.svg"
                  alt="Logo"
                />
              </div>
              <div class="utama">
                Rp{{
                  Intl.NumberFormat().format(this.pemasukan - this.pengeluaran)
                }}
              </div>
              <div class="mt-2 text-start pemasukan">
                Pemasukan
                <span class="float-right pengeluaran"> Pengeluaran </span>
              </div>
              <div class="text-start pemasukan">
                Rp{{ Intl.NumberFormat().format(this.pemasukan) }}
                <span class="float-right pengeluaran"
                  >Rp{{ Intl.NumberFormat().format(this.pengeluaran) }}
                </span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="mt-2">
          <v-form @submit="save">
            <v-text-field
              v-model="input.name"
              dense
              label="Nama Transaksi"
              outlined
              color="primary"
            ></v-text-field>
            <v-text-field
              v-model="input.nomor"
              dense
              label="Nominal Transaksi"
              outlined
              color="primary"
            ></v-text-field>
            <v-radio-group v-model="input.type" row>
              <v-radio
                class="green--text"
                style="color: #08ab3f"
                color="success"
                label="Pemasukan"
                value="pemasukan"
              ></v-radio>
              <v-radio
                class="red--text"
                style="color: #f9010b"
                color="warning"
                label="Pengeluaran"
                value="pengeluaran"
              ></v-radio>
            </v-radio-group>
            <div class="mt-15 text-center">
              <v-btn
                color="primary"
                type="submit"
                elevation="10"
                block
                @click.prevent="save"
                depressed
                >Simpan</v-btn
              >
            </div>
            <div class="mt-3 text-center">
              <v-btn
                color="white"
                style="color: #f9010b"
                elevation="10"
                block
                @click.prevent="cancel"
                depressed
                >Kembali</v-btn
              >
            </div>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import firebase from "firebase/app";
import moment from "moment";

export default {
  data: () => ({
    input: {
      type: "pemasukan",
      name: "",
      nomor: "",
    },
    items: [
      {
        type: "pengeluaran",
        namaTransaksi: "Beli Laptop",
        tanggalTransaksi: "Jumat, 5 Maret 2021",
        nominal: "Rp. 250000",
      },
      {
        type: "pemasukan",
        namaTransaksi: "Beli Laptop",
        tanggalTransaksi: "Jumat, 5 Maret 2021",
        nominal: "Rp. 250000",
      },
      {
        type: "pengeluaran",
        namaTransaksi: "Beli Laptop",
        tanggalTransaksi: "Jumat, 5 Maret 2021",
        nominal: "Rp. 250000",
      },
      {
        type: "pemasukan",
        namaTransaksi: "Beli Laptop",
        tanggalTransaksi: "Jumat, 5 Maret 2021",
        nominal: "Rp. 250000",
      },
      {
        type: "pengeluaran",
        namaTransaksi: "Beli Laptop",
        tanggalTransaksi: "Jumat, 5 Maret 2021",
        nominal: "Rp. 250000",
      },
      {
        type: "pemasukan",
        namaTransaksi: "Beli Laptop",
        tanggalTransaksi: "Jumat, 5 Maret 2021",
        nominal: "Rp. 250000",
      },
    ],
    pemasukan: 0,
    pengeluaran: 0,
    dataDashboard: [],
  }),
  mounted() {
    this.getDatas();
  },
  computed: {
    ...mapState("transaksi", ["data"]),
    ...mapState("transaksi", ["dataPush"]),
  },
  methods: {
    ...mapMutations("transaksi", ["SET_DATA"]),
    ...mapActions("transaksi", ["getData"]),
    ...mapMutations("transaksi", ["SET_DATA"]),
    ...mapActions("transaksi", ["saveAction"]),

    async getDatas() {
      await this.getData().then(() => {
        console.log("gettt");
        this.setData();
      });
    },

    setData() {
      console.log("gettt222");
      this.dataDashboard = this.data;
      this.pengeluaran = sessionStorage.getItem("pengeluaran");
      this.pemasukan = sessionStorage.getItem("pemasukan");
      console.log(this.dataDashboard);
    },
    save() {
      if (this.input.name != "" && this.input.nomor != "") {
        console.log(
          "id key",
          this.randomString(
            32,
            "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
          )
        );
        this.saveAction({
          title: this.input.name,
          nominal: parseInt(this.input.nomor),
          date: moment().locale("id").format("dddd, DD MMM YYYY"),
          id: this.randomString(
            32,
            "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
          ),
          status: this.input.type,
        }).then(() => {
          this.dialog = true;
          this.color = "success";
          this.icon = "mdi-check-circle-outline";
          this.message = "Berhasil Diubah";
          setTimeout(() => {
            this.$router.push({ name: "Home" });
          }, 3000);
        });
      } else {
        this.color = "error";
        this.icon = "mdi-alert-circle-outline";
        this.message = "Field Tidak Boleh Kosong";
        this.dialog = true;
      }
    },
    cancel() {
      this.$router.push({ name: "Home" });
    },
    randomString(length, chars) {
      var result = "";
      for (var i = length; i > 0; --i)
        result += chars[Math.floor(Math.random() * chars.length)];
      return result;
    },
    sumIncome() {
      console.log("asususu", this.dataTransaction);
      if (this.dataTransaction.length === 0) {
        let items = [0];
        const reducer = (total, currentValue) => total + currentValue;
        this.datasetIncome = items.reduce(reducer);
      } else {
        let items = [];
        this.dataTransaction.forEach((value) => {
          if (value.status === "pemasukan") {
            var childData = value.nominal;
            items.push(childData);
          }
        });
        const reducer = (total, currentValue) => total + currentValue;
        this.datasetIncome = items.reduce(reducer);
      }
    },
    sumOutCome() {
      if (this.dataTransaction.length === 0) {
        let items = [0];
        const reducer = (total, currentValue) => total + currentValue;
        this.datasetOutcome = items.reduce(reducer);
      } else {
        let items = [];
        this.dataTransaction.forEach((value) => {
          if (value.status === "pengeluaran") {
            var childData = value.nominal;
            items.push(childData);
          }
        });
        const reducer = (total, currentValue) => total + currentValue;
        this.datasetOutcome = items.reduce(reducer);
      }
    },
  },
};
</script>

<style scoped>
.green--text /deep/ label {
  color: #08ab3f;
}

.red--text /deep/ label {
  color: #f9010b;
}
</style>