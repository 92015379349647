import firebase from "firebase/app";

const defaultState = {
  loginState: [],
  loginStatus: "false",
  authenticated: false,
};

const mutations = {
  setLoginData(state, em) {
    state.loginState = em;
  },
  setLoginStatus(state, em) {
    state.loginStatus = em;
  },
  setAuth(state, em) {
    state.authenticated = em;
  },
};

const actions = {
  async loginAction({ commit }, data) {
    await firebase
      .database()
      .ref("User")
      .child(data.username)
      .once("value", (snapshot) => {
        if (snapshot.val() !== null) {
          if (snapshot.val().password === data.password) {
            commit("setLoginStatus", "true");
            commit("setLoginData", snapshot.val());
            sessionStorage.setItem("loginSession", data);
            sessionStorage.setItem("usernameSession", data.username);
          } else {
            commit("setLoginStatus", "false");
            sessionStorage.removeItem("loginSession");
            sessionStorage.removeItem("usernameSession");
          }
        } else {
          commit("setLoginStatus", "false");
          sessionStorage.removeItem("loginSession");
          sessionStorage.removeItem("usernameSession");
        }
      });
  },

  async logoutAction({ commit }) {
    commit("setLoginStatus", "false");
    sessionStorage.removeItem("pemasukan");
    sessionStorage.removeItem("pengeluaran");
    sessionStorage.removeItem("loginSession");
    sessionStorage.removeItem("usernameSession");
    sessionStorage.removeItem("idForEdit");
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
