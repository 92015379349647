<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import Login from "./views/Login";

export default {
  name: "App",
  data: () => ({}),
  components: {
    Login,
  },
  mounted() {},
};
</script>

<style scoped>
</style>
