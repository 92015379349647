import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Transaksi from "../views/Transaksi.vue";
import EditTransaksi from "../views/EditTransaksi.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/add",
    name: "Transaksi",
    component: Transaksi,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/edit/:id",
    name: "EditTransaksi",
    component: EditTransaksi,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = sessionStorage.getItem("loginSession");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.name !== "Login" && !loggedIn) next({ name: "Login" });
    else next();
  } else next();
});

export default router;
