import firebase from "firebase/app";
import "firebase/database";

let config = {
  apiKey: "AIzaSyCYYiSgdWkmBagkBfcUzX8jSpmLMyw9JrQ",
  authDomain: "catatuang-3936b.firebaseapp.com",
  databaseURL: "https://catatuang-3936b-default-rtdb.firebaseio.com",
  projectId: "catatuang-3936b",
  storageBucket: "catatuang-3936b.appspot.com",
  messagingSenderId: "153436974870",
  appId: "1:153436974870:web:d40a5e9d262eb715886a37",
  measurementId: "G-9TDWBWPQ77",
};

firebase.initializeApp(config);

export default firebase.database();
