import firebase from "firebase/app";

const defaultState = {
  data: [],
  dataPush: [],
};

const mutations = {
  SET_DATA(state, em) {
    state.data = em;
    console.log("DATA", em);
  },
  SET_DATA_PUSH(state, em) {
    state.dataPush = em;
    console.log("DATA", em);
  },
};

const actions = {
  async getData({ commit }) {
    var username = sessionStorage.getItem("usernameSession");
    var database = firebase.database().ref("User");
    await database
      .child(username)
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          commit("SET_DATA", data);
        } else {
          console.log("No data available");
        }
      })
      .catch(function(error) {
        console.error(error);
      });
  },
  async saveAction({ commit }, data) {
    var username = sessionStorage.getItem("usernameSession");
    var database = firebase.database().ref("transaksi");
    await database
      .child(username)
      .child(data.id)
      .once("value", (snapshot) => {
        console.log("id key", data.id);
        database
          .child(username)
          .child(data.id)
          .set({
            title: data.title,
            nominal: parseFloat(data.nominal),
            status: data.status,
            id: data.id,
            date: data.date,
          });
        commit("SET_DATA_PUSH", snapshot.val());
      });
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
