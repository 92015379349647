<template>
  <v-app class="home">
    <v-container fill-height="fill-height">
      <v-layout align-center="align-center" justify-center="justify-center">
        <v-flex class="login-form text-xs-center">
          <v-row align="center" justify="center" no-gutters>
            <v-col align="center" cols="12" sm="6" md="5" lg="4">
              <v-img class="mb-10" src="../assets/registergambar.png"> </v-img>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="12" sm="6" md="5" lg="4">
              <v-form @submit.prevent="login" v-model="isValid">
                <v-text-field
                  v-model="input.name"
                  dense
                  :rules="[rules.required]"
                  label="Nama Lengkap"
                  outlined
                  color="primary"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="input.username"
                  dense
                  :rules="[rules.required]"
                  label="Username"
                  outlined
                  color="primary"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="input.profesi"
                  dense
                  :rules="[rules.required]"
                  label="Profesi"
                  outlined
                  color="primary"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="input.email"
                  dense
                  :rules="[rules.required, rules.emailMatch]"
                  label="Email"
                  outlined
                  color="primary"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="input.password"
                  dense
                  counter
                  :rules="[rules.required, rules.min]"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  outlined
                  label="Password"
                  color="primary"
                  required
                ></v-text-field>
                <div class="text-center">
                  <v-btn
                    color="primary"
                    type="submit"
                    elevation="10"
                    block
                    @click.prevent="login"
                    depressed
                    :disabled="!isValid"
                    >Daftar</v-btn
                  >
                </div>
              </v-form>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" no-gutters>
            <v-col align="center" cols="12" sm="6" md="5" lg="4">
              <span>Sudah Punya Akun ? </span>
              <a v-on:click="toLogin">Masuk</a>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="justify-center"
          ><v-icon size="80" :color="color">
            {{ icon }}
          </v-icon></v-card-title
        >
        <v-card-text class="title text-center">
          {{ message }}
        </v-card-text>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn :color="color" outlined @click="dialog = false" type="submit"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
// import router from "@/router/index";

export default {
  data: () => ({
    icon: "",
    color: "",
    message: "",
    loading: false,
    dialog: false,
    show1: false,
    isValid: true,
    input: {
      name: "",
      username: "",
      profesi: "",
      email: "",
      password: "",
    },

    portrait: true,
    landscape: false,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      emailMatch: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
  }),
  mounted() {
    window.addEventListener("orientationchange", this.handleOrientationChange);
  },
  computed: {
    ...mapState("regis", ["regisStatus"]),
  },
  methods: {
    ...mapActions("regis", ["regisAction"]),
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        // portrait mode
        this.landscape = false;
        this.portrait = true;
      } else if (orientation === "landscape-primary") {
        // landscape mode
        this.landscape = true;
        this.portrait = false;
      }
    },
    login() {
      // this.dialog = true;
      // this.color = "success";
      // this.icon = "mdi-check-circle-outline";
      // this.message = "Berhasil";
      // setTimeout(() => {
      //   sessionStorage.setItem("loginSession", "data");
      //   this.$router.push({ name: "Dashboard" });
      // }, 5000);
      // router.push({ name: "Home" });
      this.regisAction({
        nama: this.input.name,
        username: this.input.username,
        profesi: this.input.profesi,
        email: this.input.email,
        password: this.input.password,
        saldo: 0,
        pemasukan: 0,
        pengeluaran: 0,
      }).then(() => {
        console.log("data", this.regisState);
        console.log("login", this.regisStatus);
        if (this.regisStatus === "true") {
          this.dialog = true;
          this.color = "success";
          this.icon = "mdi-check-circle-outline";
          this.message = "Berhasil Daftar";
          setTimeout(() => {
            this.$router.push({ name: "Home" });
          }, 5000);
        } else {
          this.color = "error";
          this.icon = "mdi-alert-circle-outline";
          this.message = "Username Sudah Ada";
          this.dialog = true;
        }
      });
    },
    toLogin() {
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style></style>